.k-panelbar {
    
    border-color: grey !important;
    padding: 10px !important;
    .k-expanded{
        .k-link {
            &.k-selected.k-focus{
                background-color: #ffe9d3;
            border: 1px solid #f58a1f;
                color: #000 !important;
                outline: none;
                border-bottom-right-radius: 0px!important;
                border-bottom-left-radius: 0px!important;
            }
            
            
           
            
        }
    }
    .k-panelbar-header {
        border-radius: 5px;
        .k-link {
            padding: 7px 16px;
            border-radius: 10px;

            &:hover {
                background-color: #ebebeb !important;
            }
            
        }
    }
}
.k-breadcrumb {
    background-color: grey;
}


.k-display-none {
    display: none;
}
