@import '../../variable';
.text-normal {
    color: $text-color;
    font-family: $regular;
}
.building-details-sec {
    margin-bottom: 15px;
    h5 {
        font-family: $font-Medium;
        color: black;
    }
}
button {
    &.active-rooms {
        background-color: $primary;
        border-color: $primary;
        color: white;
    }
    &.position-buttion {
        position: absolute;
        top: -40px;
        right: 0px;
        z-index: 100;
        padding: 5px;
    }
    &.position-buttion-second {
        position: absolute;
        top: -44px;
        right: 150px;
        z-index: 100;
    }
    &.add-edit-btn {
        border: none;
        background: none;
        color: $primary;
        text-decoration: underline;
    }
    &.tag-buttons {
        border: none;
        background: none;
        padding: 0;
        position: relative;
        float: right;
    }
}
.col3-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    background-color: #fff;
    .k-listbox {
        width: 100%;
        .k-list-scroller {
            padding: 10px 0 10px 0;
            margin-bottom: 10px;
            border: none;
        }
    }
    .k-selected {
        background: $primary !important;
        border-radius: 5px;
        .k-grid-edit-command {
            path {
                fill: #fff;
            }
        }
    }
}

.bg-white-head {
    background: #fff;
    padding: 15px 0px 0px;
    position: relative;
}
.w-4x {
    width: 276px;
}

.search-container {
    margin-bottom: 10px;
}

.buildings-container {
    display: grid;
    > div {
        &:not(:first-child) {
            padding: 0 15px;
        }
        h5 {
            text-transform: capitalize;
        }
    }
    //grid-template-columns: 1fr 1fr 130px 130px 130px 130px; //Ragu
    grid-template-columns: 1fr 120px 120px 120px 210px;
    /* .span-2-col {
        grid-column: 1 / span 2;
    } */
    .del-btn-position {
        position: relative;
        right: -23px;
    }
}

.k-panelbar-item-text {
    width: 100%;
}
.k-panelbar {
    border: 0;
    background: none;
    border-color: transparent;

    .k-panelbar-toggle {
        &.k-panelbar-expand {
            svg {
                display: none;
            }
            &::before {
                content: '';
                background-image: url(../../images/caret-down.svg);
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
                display: inline-block;
                background-position: center;
            }
        }
        &.k-panelbar-collapse {
            svg {
                display: none;
            }
            &::before {
                content: '';
                background-image: url(../../images/caret-down.svg);
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
                display: inline-block;
                background-position: center;
                transform: rotate(180deg);
            }
        }
    }
    .k-animation-container {
        background-color: #fff;
    }
    .k-link {
        &:hover {
            background-color: white !important;
        }
        &.k-state-focus {
            box-shadow: none !important;
        }
        &.k-state-selected {
            background-color: #fff !important;
            .k-panelbar-toggle {
                color: $text-color !important;
            }
        }
        div {
            color: $text-color;
        }
        h6 {
            color: $text-color;
        }
        h4 {
            margin-bottom: 0px;
            font-family: $Semibold;
            color: $primary;
            &.text-normal {
                color: $text-color;
                font-family: $regular;
            }
        }
    }
    .k-panelbar-header {
        border-style: solid;
        border-color: inherit;
        display: block;
        border: 1px solid grey;
    }

    li {
        margin-bottom: 5px;
        border-radius: 10px;
        overflow: hidden;
    }
}
.building-details {
    .k-tabstrip {
        position: relative;
        margin-left: -30px;
        background-color: #fff !important;
        margin-right: -30px;
        padding: 0 30px !important;
        min-height: 50vh;
        .k-content {
            border: none;
            background: none;
            //padding: 40px 20px;
        }
        .k-tabstrip-content {
            background: none;
            border: none;
            padding: 0px;
        }
    }

    .k-tabstrip-items-wrapper {
        z-index: initial;
        border: none;
        position: relative;
        .k-item {
            font-family: $font-Medium;
            font-size: 1.1rem;
            margin-right: 30px;
            color: $text-color;
            text-transform: uppercase;
            &.k-state-active {
                color: $primary;
                border: none;
                &::after {
                    content: '';
                    background: $primary;
                    display: block;
                    height: 3px;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }
    .k-expander {
        margin: 0 !important;
    }
}
.structureTab {
    overflow: inherit !important;
    .k-animation-container {
        width: 100%;
    }
    .structureList-grid {
        > div {
            position: relative;
            &.options-list {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
        }
        display: grid;
        grid-template-columns: 150px 150px auto 40px 40px 40px 40px;
        column-gap: 30px;
        align-items: center;
        h5,
        h6 {
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
        .tagtop {
            top: -6px;
            position: absolute;
            background: $primary;
            width: 19px;
            height: 19px;
            text-align: center;
            color: #fff;
            border-radius: 15px;
            font-size: 11px;
            left: 6px;
            line-height: 20px;
        }
    }
}

.boservation-grid {
    display: flex;
    background-color: $gray-bg;
    border-radius: 5px;
    margin-bottom: 6px;
    &.active {
        background-color: #ffe9d3;
        //border: 1px solid #f58a1f;
        &:hover {
            background-color: #ffe9d3;
        }
    }
    &:hover {
        background-color: #ebebeb;
    }
    cursor: pointer;

    padding: 12px;
    column-gap: 10px;
    &.colmn-5 {
        grid-template-columns: 1fr 1fr 1fr auto auto;
    }
    &.colmn-6 {
        grid-template-columns: 1fr 1fr 1fr auto auto auto;
    }
    > div {
        &:empty {
            display: none;
        }
        &:nth-last-child(2) {
            &:has(button) {
                margin-left: auto;
            }
        }
        &:nth-child(1) {
            flex: 25% 0 0;
        }
        &:not(:has(button)):nth-last-child(2) {
            + div {
                margin-left: auto;
            }
        }

        position: relative;
        color: $text-color;
        &:nth-last-of-type(2) {
            margin-left: auto;
            margin-right: 20px;
        }
        &:nth-child(1) {
            font-family: $font-Medium;
        }
    }
    .tagtop {
        top: -6px;
        position: absolute;
        background: $primary;
        width: 19px;
        height: 19px;
        text-align: center;
        color: #fff;
        border-radius: 15px;
        font-size: 11px;
        left: 6px;
        line-height: 20px;
    }
}
.imageGallery {
    .k-dialog {
        width: 60vw;
    }
    .heroImage {
        text-align: center;
        img {
            max-width: 91%;
        }
    }
    .thumbimage {
        display: flex;
        align-items: center;

        column-gap: 10px;
        > div {
            text-align: center;
        }
        img {
            width: 90%;
            border-radius: 10px;

            &.active {
                border: 5px solid $primary;
            }

            &.back-arrow,
            &.next-arrow {
                width: 15px;
            }
        }
    }
}
.select {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        background-image: url(../../images/caret-down.svg);
        background-repeat: no-repeat;
        right: 6px;
        top: 17px;
    }
}
.image-row {
    display: grid;
    grid-template-columns: 250px 1fr;
    > div {
        background: #fff;
        &:first-child {
            background-color: white;
            padding: 20px;
        }
        height: calc(100vh - 154px);
        &:last-child {
            background: #e1e0e0;
        }
    }
}
.image-drawing {
    .customChkbox {
        label {
            font-size: 14px;
        }
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                background-color: #ffffff;
                &:after {
                    display: block;
                }
            }
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 22px;
            width: 22px;
            background-color: #fff;
            border-style: solid;
            border-width: 2px;
            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid #adabab;
                border-width: 0px 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    .shape-info {
        background-color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .pop-tool {
        white-space: nowrap;
        background-color: #fff;
        padding: 10px 10px;
        box-shadow: 5px 3px 6px 0px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        &.contol-icons {
            button {
                background-color: transparent;
                border: none;
                width: 40px;
            }
        }
        position: relative;
        &::after {
            content: '';
            position: absolute;
            display: block;
            height: 15px;
            width: 15px;
            z-index: -1;
            background-color: #fff;
            bottom: -6px;
            left: 7px;
            transform: rotate(40deg);
        }
    }
    .currentMode {
        position: absolute;
        top: 16px;
        left: 76px;
        font-style: italic;
    }
    .canvas-wrap {
        position: relative;
        margin: auto;
        height: calc(100vh - 154px);
    }
    .image-toolwrap {
        &.full-screen {
            .toolImg {
                height: calc(100vh);
            }
        }
        display: flex;
        border-left: 1px solid #eee;
        align-items: center;
        > div {
            &:last-child {
                flex: 1;
            }
        }
        canvas {
            display: inherit;
        }
        .toolImg {
            position: relative;
            background: #fff;
            width: 67px;
            height: calc(100vh - 154px);
            box-shadow: 5px 0px 0px 0px rgba(0, 0, 0, 0.05);
            button {
                padding: 0;
                margin: 0;
                border: none;
                display: block;
                margin: 38px auto;
                img {
                    width: 32px;
                    &.draw-icon {
                        width: 26px;
                    }
                }
            }
            .selected-tool {
                background-color: #eecf45;
            }

            .unselected-tool {
                background-color: transparent;
            }
        }
    }
}

.linkWindow {
    margin-top: -16px;
    margin-left: -16px;
    .pop-tool {
        white-space: nowrap;
        background-color: #fff;
        padding: 10px 10px;
        box-shadow: 5px 3px 6px 0px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        &.contol-icons {
            button {
                background-color: transparent;
                border: none;
                width: 40px;
            }
        }
        position: relative;
        &::after {
            content: '';
            position: absolute;
            display: block;
            height: 15px;
            width: 15px;
            z-index: -1;
            background-color: #fff;
            bottom: -6px;
            left: 7px;
            transform: rotate(40deg);
        }
    }
    .k-window-content {
        padding: 0;
    }
    .currentMode {
        position: absolute;
        bottom: -1px;
        left: 436px;
        font-style: italic;
    }
    .canvas-wrap {
        position: relative;
        margin: auto;
        height: calc(100vh - 154px);
    }
    .image-toolwrap {
        display: flex;
        border-left: 1px solid #eee;
        align-items: center;
        > div {
            &:last-child {
                flex: 1;
            }
        }
        canvas {
            display: inherit;
        }
        .toolImg {
            position: relative;
            background: #fff;
            width: 67px;
            height: calc(100vh - 154px);
            box-shadow: 5px 0px 0px 0px rgba(0, 0, 0, 0.05);
            button {
                padding: 0;
                margin: 0;
                border: none;
                display: block;
                margin: 38px auto;
                background-color: transparent;
                img {
                    width: 32px;
                    &.draw-icon {
                        width: 26px;
                    }
                }
            }
        }
    }
}
.obserTitle {
    font-size: 19px !important;
    line-height: 31px;
    .checkmark {
        top: 5px !important;
    }
    margin-bottom: 10px;
}
.copy-observation-list {
    max-height: 60vh;
    overflow: auto;
    margin-top: 5px;
}

span.k-panelbar-item-text {
    user-select: text;
}

.k-window-titlebar-actions {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    line-height: 1;
    margin-block: -0.5em !important; // Change for x Button
    margin-inline: 0;
    margin-inline-end: -0.25rem;
    margin-inline-end: calc(var(--kendo-spacing-3, 0.75rem) - var(--kendo-spacing-4, 1rem));
    vertical-align: top;
}
.tabwhitespace.inspectiontab {
    .k-tabstrip-items-wrapper {
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 0px;
        padding-right: 0px;
        background: #fff;
        .k-item {
            color: gray;
        }
        .k-active {
            color: black !important;
        }
    }
}
.tabwhitespace,
.building-details {
    .k-tabstrip-items-wrapper {
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
        padding-right: 30px;
        background: #fff;
    }
    .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active,
    .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active,
    .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active:focus {
        border-left: none !important;
        border-top: none !important;
        border-right: none !important;
        border-bottom: 4px solid #f58a1f !important;
        border-bottom-color: #f58a1f !important;
        outline: none;
        box-shadow: none;
    }
}
.observation_2col {
    display: grid;
    background: #fff;
    margin-bottom: 10px;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    > div {
        &:nth-child(2) {
            overflow-y: scroll;
            border: 1px solid #ddd;
            border-radius: 7px;
            padding: 15px;
        }
    }
}
.photoGal-wrap {
    background: #fff;
    padding: 15px;
}

.bg_white_stack {
    background: #fff;
}
.stack_title {
    display: flex;
    padding: 10px 0 0;
    align-items: center;
    > div {
        &:last-child {
            margin-left: auto;
        }
    }
    h4 {
        margin-bottom: 0px;
        font-size: 19px;
        color: #f58a1f;
    }
}

.tabfix {
    position: relative;
    background-color: #fff !important;
    margin-left: -30px;
    margin-right: -30px;
    padding: 0 30px !important;
}
.alertDesign {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: initial;
    .images {
        background-image: url(../../images/alert-image.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 200px;
        height: 150px;
        background-size: 140px;
        margin: 0 auto;
    }
}
.fontMedium {
    font-family: $font-Medium;
}
.k-panelbar-item.k-panelbar-header {
    overflow: inherit;
    .k-animation-container {
        &:empty {
            display: none !important;
        }
        border: 1px solid #ddd;
    }
}
