.image-list-dialog {
    .k-dialog {
        width: 75vw;
        max-height: 43vw;
    }
    .k-listbox {
        height: 100%;
        width: 100%;
        .k-list {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
