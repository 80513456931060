.permissions-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 15px;
    row-gap: 15px;
    line-height: 20px;
}
.roleHead {
    display: flex;
}
