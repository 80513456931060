@import '../../variable';
.masterList {
    .k-item {
        .k-link {
            color: $text-color;
            font-size: 1.1rem;
            &.k-state-selected {
                color: $text-color;
            }
        }
        th {
            .k-link {
                font-size: initial !important;
            }
        }
    }
    .k-child-animation-container {
        .k-panelbar-content {
            padding: 0px 20px 20px;
        }
    }

    .k-state-selected {
        .k-panelbar-item-text {
            color: $primary;
        }
    }
}
