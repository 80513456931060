.header {
    .logo {
        width: 240px;
    }
    .logout {
        max-height: 20px;
        margin-top: -10px;
        position: relative;
    }
    .navbar-brand {
        padding: 0;
        svg {
            max-width: 134px;
        }
    }
}
.user-name {
    padding: 0 13px 0 0;
}
