@import './../../variable';
.loginWrap {
    background-color: $gray-bg;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-container {
    margin: auto;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 16px;
    max-width: 800px;
}
.logincontent {
    width: 480px;
}

a {
    color: #0d6efd;
    text-decoration: none;
}

.loginWrapper {
    max-width: 450px;
}
.logo {
    max-width: 280px;
}
.btn.btn-normal {
    background: $primary !important;
    color: white;
}
.mt100 {
    margin-top: 150px;
}
