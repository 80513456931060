.k-dialog {
    width: 50vw;
}
.k-dialog-buttongroup {
    border: 0;
}

.k-grouping-row .k-command-cell {
    display: none;
}
@media (min-height: 602px) {
    .react-datepicker {
        top: 22px;
        transform: scale(1.2);
    }
}

.datepickerfix {
    .react-datepicker-wrapper {
        width: 100%;
    }
}
.react-datepicker-wrapper {
    input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
    }
}

.report-header {
    position: absolute;
    top: 4px;
    left: 35px;
}
.report-footer {
    position: absolute;
    bottom: 10px;
    right: 50px;
}
.labelfix {
    label {
        display: block;
        margin-bottom: 4px;
    }
}
