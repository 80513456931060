.k-multiselect .k-list {
    display: flex;
    flex-wrap: wrap;
}

.k-multiselect .k-list .k-item {
    flex: 1 0 45%; /* Adjust the percentage as needed */
    margin: 5px;
    padding: 20px;
}

.k-multiselect .k-list .k-item:first-child {
    flex-basis: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.k-multiselect .k-list .k-item:hover .kendo-color-primary-active {
    background-color: #ffe9d4;
    color: black;
}

.k-multiselect .k-list .k-item.k-state-selected .kendo-color-primary-active {
    background-color: #ffe9d4;
    color: black;
}
